body::-webkit-scrollbar {
	display: none;
}
body {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* show scrollbar for Chrome, Safari and Opera */
.show-scrollbar {
	overflow-x: scroll;
}
.gradient-custom {
	/* fallback for old browsers */
	background: #fafaf5;
}

.window {
	height: 100vh;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10000000;
}

.no-scrollbar-scroll::-webkit-scrollbar {
	display: none;
}

.no-scrollbar-scroll {
	ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.notifications-number {
	height: 18px;
	width: 18px;
	border-radius: 50%;
	color: white;
	background-color: red;
	position: absolute;
	font-size: 12px;
	font-weight: 900;
	padding: 4px;
	inset-inline-start: 50%;
	inset-block-start: -3px;
}

.hover-effect:hover {
	opacity: 0.5;
	cursor: pointer;
}
