.mainCardContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 190px;
	padding: 20px 30px 10px 15px;
}
.titlePaperContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.titleBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: -3px;
	white-space: nowrap;
}
.votesNumCont {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: flex-end;
	gap: 5px;
}

.discreptionTypo {
	position: relative;
	text-overflow: ellipsis;
	max-height: 3em;
	line-height: 1.2em;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	color: #9c9c9c;

	/* font-size: 14px; */
	/* color: black;
	width: 250px; Could be anything you like. */
}

/* @media screen and (max-width: 500wpx) { */
/* .featuresMainContainer { */
/* padding-top: 70px; */
/* height: calc(100vh - 179px); */
/* } */
/* } */
