.home-page-container {
	padding: 12px;
}

.dashboard-item {
	padding: 8px;
	box-shadow: 1px 1px 6px;
	border-radius: 8px;
}

.dashboard-container {
	margin-top: 16px;
	padding: 16px;
	/* box-shadow: 0.3px 0.3px 4px; */
	border-radius: 8px;
	width: 100%;
}

.product-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 200px;
	border: 0.2px solid rgb(186, 186, 186);
	/* box-shadow: 1px 1px 6px; */
	/* border-radius: 8px; */
}

.product-avatar {
	width: 65px;
	height: 65px;
	object-fit: contain;
}

.dashboard-title {
	margin-bottom: 16px;
}
