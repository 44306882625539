* {
	box-sizing: border-box;
	margin: 0;
	font-family: tajawal, sans-serif;
}
.file-input {
	display: none;
}

.label {
	font-weight: bold;
	margin-bottom: 8px;
}

.file-upload-btn {
	color: black;
	text-transform: uppercase;
	outline: none;
	border-style: none;
	border-radius: 4px;
	background-color: #ebbc53;
	font-weight: bold;
	font-size: 12px;
	padding: 10px;
}
.file-upload-btn:hover {
	background-color: #e4b040;
}

.container {
	/* transform: translateY(-100%); */
}

.container p {
	color: red;
	text-align: center;
}

.drop-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	width: 100%;
	height: 100%;
	padding: 32px;
	/* height: 200px; */
	border: 2px dashed lightgray;
}

.drop-message {
	display: flex;
	flex-direction: column;
	text-align: center;
	color: gray;
	font-family: Arial;
	font-size: 20px;
	gap: 10px;
}

.file-progressBar {
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	display: inline-block;
	margin: 0px 10px 5px 5px;
	vertical-align: top;
	flex: 6 !important;
	margin-left: 50px;
	box-sizing: content-box;
	width: calc(100% - 50px);
}

.file-progressBar div {
	height: auto;
	color: #fff;
	text-align: right;
	line-height: 15px;
	width: 0;
	background-color: #4caf50;
	border-radius: 3px;
	font-size: 13px;
}

.file-display-container {
	/* position: fixed; */
	/* width: 805px; */
}

.file-status-bar {
	width: 100%;
	/* vertical-align: top; */
	margin-top: 10px;
	margin-bottom: 10px;
	/* position: relative; */
	/* line-height: 50px; */
	/* height: 50px; */
}

.file-status-bar > div {
	overflow: hidden;
	display: flex;
	align-items: center;
	gap: 10px;
}

.file-name {
	display: inline-block;
	vertical-align: top;
	color: gray;
}

.file-error {
	display: inline-block;
	vertical-align: top;
	margin-left: 50px;
	color: #9aa9bb;
}

.file-error-message {
	color: red;
}

.file-size {
	display: inline-block;
	vertical-align: top;
	color: #30693d;
	margin-left: 10px;
	margin-right: 5px;
	margin-left: 10px;
	color: #444242;
	font-weight: 700;
	font-size: 14px;
}

.file-remove {
	/* position: absolute; */
	/* top: 20px; */
	/* right: 10px; */
	/* line-height: 15px; */
	cursor: pointer;
	color: red;
	/* margin-right: -10px; */
}

.modal {
	z-index: 999;
	display: none;
	overflow: hidden;
}

.modal .overlay {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.66);
	position: absolute;
	top: 0;
	left: 0;
}

.modal .modal-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	object-fit: cover;
	width: 100%;
	height: 300px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.modal-image-text {
	position: absolute;
	color: red;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
}

.close {
	position: absolute;
	top: 15px;
	right: 35px;
	color: #f1f1f1;
	font-size: 40px;
	font-weight: bold;
	transition: 0.3s;
}

.upload-modal {
	z-index: 999;
	display: none;
	overflow: hidden;
}

.upload-modal .overlay {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.66);
	position: absolute;
	top: 0;
	left: 0;
}

.progress-container {
	background: white;
	width: 500px;
	height: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
}

.progress-container span {
	display: flex;
	justify-content: center;
	padding-top: 20px;
	font-size: 20px;
}

.progress {
	width: 90%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #efefef;
	height: 20px;
	border-radius: 5px;
}

.progress-bar {
	position: absolute;
	background-color: #4aa1f3;
	height: 20px;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: bold;
}

.error {
	color: red;
}

.preview-container {
	width: 200px;
	height: 200px;
	position: relative;
}

.remove-btn {
	position: absolute;
	right: 5px;
	top: 5px;
}
