.featuresMainContainer {
	width: 100%;
	height: calc(100vh - 116px);
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	/* justify-content: flex-start; */
	/* align-items: center; */
	overflow: hidden;
	/* padding-top: 15px; */
}

.featuresHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.GridsContainers {
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	padding-bottom: 40vh;
	/* scrollbar-width: none; */
	/* -webkit-scrollbar-width: none; */
	-webkit-overflow-scrolling: touch;
	z-index: 1;
	/* white-space: nowrap; */
}

.TabPanelCont {
	/* width: 100%; */
	/* height: 100%; */
}

@media screen and (max-width: 500wpx) {
	.featuresMainContainer {
		/* padding-top: 70px; */
		height: calc(100vh - 88px);
		justify-content: center;
		align-items: stretch;
		padding-bottom: 2vh;
	}
	.GridsContainers {
		width: 100vw;
		/* flex-basis: 1; */
		padding-bottom: 40vh;
	}
}
@media screen and (max-width: 1279px) {
	.featuresHeader {
		padding: 0 2%;
	}
}
